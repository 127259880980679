import React from "react";
import Slider from "react-slick";
//import LeftArrowIcon from "Assets/Images/Slider/banner_left_arrow_unfill.png";
//import RightArrowIcon from "Assets/Images/Slider/banner_right_arrow_unfill.png";

import "Styles/slick.min.css";

const settings = {
  dots: false,
  //infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

export default function BannerSlider(props) {
  const { sliderData } = props;

  return (
    <div className="advertisment-container">
      <div className="container">
        <Slider {...settings} className="slick-slider">
          {sliderData.list_of_data &&
            sliderData.list_of_data.map((item, index) => (
              <div className="media-container" key={index}>
                <div
                  className="image"
                  style={{ backgroundImage: `url(${item.url})` }}
                ></div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
}
