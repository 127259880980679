import styled from "styled-components";
import Logo from "Assets/Images/TopBar/logo.png";
import { AppBar, Toolbar, Popover, Drawer } from "@material-ui/core";
import { FONTS, COLORS, responsive } from "Styles/Constants";
import MenuIcon from "Assets/Images/TopBar/menu.svg";
import SearchIcon from "Assets/Images/TopBar/search.png";
import CartIcon from "Assets/Images/TopBar/shopping-cart.png";
import LocationIcon from "Assets/Images/TopBar/location.png";
import TranslationIcon from "Assets/Images/TopBar/translation.png";
import LogoSmallIcon from "Assets/Images/TopBar/small-icon.png";

export const AppBarWrapper = styled(AppBar)`
  &.root {
    height: 80px;
    box-shadow: none;
    background-color: ${COLORS.PRIMARY};
    ${responsive.MOBILE`
      height: 140px;
    `}
  }

  .link-container {
    background-color: #10271b;
    /*top: 65px;
    position: fixed;
    margin: 0 auto;
    right: 0;
    left: 0;*/
  }
`;

export const ToolbarWrapper = styled(Toolbar)`
  //background-color: ${COLORS.PRIMARY};

  &.gutters {
    min-height: 65px;
    padding-left: 15px;
    padding-right: 15px;

    ${responsive.TABLET`
      min-height: 75px;
    `}

    ${responsive.PHABLET`
      min-height: 135px;
      padding-left: 7px;
      padding-right: 7px;
    `}
  }

  .menu {
    background-image: url(${MenuIcon});
    height: 30px;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 5px 0px 0 0;

    ${responsive.TABLET`
      height: 23px;
      width: 23px;
      background-position: top center;
      margin: -3px 10px 0 0;
    `}

    ${responsive.MOBILE`
      margin: 8px 10px 0 0;
    `}

    &:hover {
      cursor: pointer;
    }
  }

  .menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${responsive.MOBILE`
      flex-wrap:wrap;  
    `}

    .logo {
      background-image: url(${Logo});
      height: 42px;
      width: 215px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      cursor: pointer;

      ${responsive.PHABLET`
          order:1;
          width: 160px;
      `}

      ${responsive.S_MOBILE`
          width: 128px;
      `}
      
    }

    .location-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;
      ${responsive.TABLET`
        margin: 0 10px;
        font-size:12px;
      `}

      ${responsive.MOBILE`
        width:65%;
        order:7
      `}

      .icon {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 20px;
        width: 20px;
        margin-right: 15px;
        ${responsive.TABLET`
          height: 17px;
          width: 17px;
        `}

        &.location {
          background-image: url(${LocationIcon});
        }
      }
      .text-container {
        cursor: pointer;
        .title {
          color: #818985;
          font-size: 14px;
          ${responsive.TABLET`
            font-size:10px;
          `}
          ${responsive.PHABLET`
            font-size:12px;
          `}
        }
        .address {
          color: #fff;
          font-size: 14px;
          ${responsive.TABLET`
            font-size:10px;
          `}
          ${responsive.PHABLET`
            font-size:12px;
          `}
        }
      }
    }

    .search-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;

      ${responsive.MOBILE`
          order:4;
          margin : 10px 0;
          width : 100%;
      `}

      input {
        padding: 10px 16px;
        color: #fff;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-color: #fff;
        color: ${COLORS.PRIMARY};
        font-family: ${FONTS.PRIMARY};
        font-size: 14px;
        max-height: 40px;
        min-height: 40px;
        min-width: 950px;

        ${responsive.DISPLAY`
          min-width: 600px;
        `}

        ${responsive.DESKTOP`
          min-width: 350px;
        `}

        ${responsive.LAPTOP`
          min-width: 100px;
        `}

        ${responsive.MOBILE`
            min-width: 85%;
        `}

        ${responsive.S_MOBILE`
            min-width: 85%;
        `}
      }
      .search {
        background-color: #90d2b0;
        padding: 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        max-height: 40px;
        min-height: 40px;
        cursor: pointer;

        .icon {
          height: 17px;
          width: 17px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer;
          margin: 0 8px;
          background-image: url(${SearchIcon});
        }
      }
    }

    .language {
      margin: 0 10px;
      ${responsive.TABLET`
          margin: 0 5px;
          font-size:10px;
      `}

      ${responsive.MOBILE`
          display:none;
      `}
      .icon {
        height: 25px;
        width: 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        background-image: url(${TranslationIcon});
      }
    }

    .account-container {
      margin: 0 15px;

      ${responsive.PHABLET`
          order:2;
          margin: 0 0 0 auto;
      `}

      .title {
        color: #818985;
        cursor: pointer;
        font-size: 14px;
        ${responsive.TABLET`
          font-size:10px;
        `}

        ${responsive.PHABLET`
          font-size:12px;
        `}
      }
      .selection-menu {
        display: flex;
        align-items: center;
        cursor: pointer;
        .item {
          color: #fff;
          font-size: 14px;
          ${responsive.TABLET`
            font-size:10px;
          `}
          ${responsive.PHABLET`
            font-size:12px;
          `}
        }
        .icon {
          height: 15px;
          width: 15px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          &.dropdownm {
            //background-image: url(${TranslationIcon});
          }
        }
      }
    }

    .order-container {
      ${responsive.MOBILE`
          order:5;
          width: 25%;
          margin-left: 10px;
      `}
      .title {
        color: #818985;
        cursor: pointer;
        ${responsive.LAPTOP`
          font-size:14px;
        `}
        ${responsive.TABLET`
          font-size:10px;
        `}
        ${responsive.PHABLET`
          font-size:12px;
        `}
      }
      .order {
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        ${responsive.LAPTOP`
          font-size:14px;
        `}
        ${responsive.TABLET`
          font-size:10px;
        `}
        ${responsive.PHABLET`
          font-size:12px;
        `}
      }
    }

    .buttons {
      display: flex;
      align-items: center;

      ${responsive.LAPTOP`
        margin : 0 0 0 auto;
      `}

      ${responsive.MOBILE`
          order:3;
      `}

      .border-container {
        padding: 7px 5px;
        border: 1px solid #96938e;
        margin: 0 8px;
        border-radius: 2px;
        ${responsive.TABLET`
          padding: 5px 2px;
        `}

        .cart {
          height: 17px;
          width: 17px;
          background-image: url(${CartIcon});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer;
          margin: 0 8px;
          ${responsive.TABLET`
            height: 12px;
            width: 12px;
          `}
        }
        .bobble {
          position: absolute;
          top: 7px;
          right: 16px;
          border: 1px solid #d1d1d1;
          background: #fff;
          color: ${COLORS.PRIMARY};
          padding: 5px;
          border-radius: 50%;
          font-size: 14px;

          ${responsive.TABLET`
            top: 10px;
            font-size: 10px;
            padding: 3px;
          `}

          ${responsive.MOBILE`
            top: 3px;
            font-size: 10px;
            padding: 3px;
            right: 12px;
          `}
          
        }
      }
    }

    .right-sidebar-icon {
      background-image: url(${MenuIcon});
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      height: 25px;
      width: 25px;
      margin: 0 0 0 auto;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${responsive.TABLET`
      max-width   : 300px;
      overflow-x  : scroll;
  `}

  &.sidebar {
    flex-direction: column;
    color: #000;
  }

  .link {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-family: ${FONTS.PRIMARY};
    min-width: max-content;
    padding: 15px 0;
    text-align: left;
    margin-left: 15px;

    &.sidebar {
      flex-direction: column;
      color: #000;
      font-size: 14px;
      &:hover,
      &.active {
        color: #818985;
        padding: 15px 0;
        border-bottom: 2px solid #000;
      }
    }

    &:hover,
    &.active {
      color: #818985;
      padding: 15px 0;
      border-bottom: 2px solid #fff;
    }
  }

  .logo {
    height: 45px;
    width: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    margin: 0 25px;

    ${responsive.DISPLAY`
      margin: 0 10px;
    `}
    &.small-icon {
      background-image: url(${LogoSmallIcon});
    }
  }
`;

export const PopOver = styled(Popover)`
  .logout {
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      cursor: pointer;
    }
  }
`;

export const SidebarWrapper = styled(Drawer)`
  .drawer-paper {
    background-color: #fafafd;
    width: 220px;
    margin: 60px 0 0;
    border-right: 1px solid ${COLORS.LINE_COLOR};
  }
`;
