import React from "react";
import { Link } from "react-router-dom";
import { FooterContainer } from "./Footer.style";
import { Button } from "@material-ui/core";
import TelephoneIcon from "Assets/Images/Footer/telephone.png";
import {
  URL_ABOUT_US,
  URL_DONATE_NOW,
  URL_GHARELU_UPCHAR,
  URL_KNOWLEDGE_CENTER,
} from "Helpers/Paths";

export default function Footer() {
  const redirectToAppDownloadPage = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.swastharogya",
      "_blank"
    );
  };

  return (
    <FooterContainer>
      <div className="container section-container">
        <div className="section-information">
          <div className="title-conainer">
            <div className="title">Information</div>
          </div>
          <div className="link-container">
            <div className="link">
              <Link to="/">Home</Link>
            </div>
            <div className="link">
              <Link to="/sub-categories/1">Shop by Product</Link>
            </div>
            <div className="link">
              <Link to={URL_KNOWLEDGE_CENTER}>Knowledge Center</Link>
            </div>
            <div className="link">
              <Link to={URL_GHARELU_UPCHAR}>Gharelu Upchar</Link>
            </div>
            <div className="link">
              <Link to={URL_DONATE_NOW}>Donate Now</Link>
            </div>
            <div className="link">
              <Link to={URL_ABOUT_US}>About Us</Link>
            </div>
          </div>
        </div>
        <div className="section-categories">
          <div className="title-conainer">
            <div className="title">Categories</div>
          </div>
          <div className="link-container">
            <div className="link">
              <Link to={"/sub-categories/1"}>Medicine</Link>
            </div>
            <div className="link">
              <Link to={"/sub-categories/16"}>Cosmetic</Link>
            </div>
            <div className="link">
              <Link to={"/sub-categories/18"}>Aryuvedic</Link>
            </div>
            <div className="link">
              <Link to={"/sub-categories/19"}>Men's Care</Link>
            </div>
            <div className="link">
              <Link to={"/sub-categories/21"}>Women's Care</Link>
            </div>
            <div className="link">
              <Link to={"/sub-categories/2"}>Skin Care</Link>
            </div>
          </div>
        </div>
        <div className="section-address">
          <div className="title-conainer">
            <div className="title">Contacts</div>
          </div>
          <div className="address">
            <div className="field">
              <span className="white">Address: &nbsp;</span>
              <span>
                332-335, Trade Square, Khokhra Cir, Maninagar, Ahmedabad,
                Gujarat 380008
              </span>
            </div>
            <div className="field">
              <span className="white">Email: &nbsp;</span>
              <span>info@swastharogya.com</span>
            </div>
            <div className="field">
              <span className="white">Phone: &nbsp;</span>
              <span>072289 56789</span>
            </div>
          </div>
          <div className="button-container">
            <Button
              variant="outlined"
              classes={{
                root: "root",
              }}
              startIcon={<img src={TelephoneIcon} alt="phone" />}
              //onClick={"document.location.href='tel:07228956789'"}
            >
              Call Now
            </Button>
          </div>
        </div>
      </div>
      <div className="section-coptright">
        <div className="container">
          <div className="coptright-container">
            <div className="playstore-container">
              <div
                className="icon play-store pointer"
                onClick={redirectToAppDownloadPage}
              />
            </div>
            <div className="copyright">
              <div className="text">Copyright@2021 Swasth Arogya Design.</div>
              <div className="text">All Rights Reserved.</div>
            </div>
            <div className="payment-providers">
              <div className="icon payu" />
              <div className="icon gpay" />
              <div className="icon paytm" />
              <div className="icon phonepe" />
              <div className="icon mobikwik" />
            </div>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
}
