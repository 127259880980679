import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GhareluUpcharListWrapper } from "./GhareluUpcharList.style";
import { Tooltip } from "@material-ui/core";

// URL
import { URL_HOME } from "Helpers/Paths";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";
import ProgressBar from "Components/Commons/ProgressBar/ProgressBar";

export default function GhareluUpcharList(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchGhareluUpcharList();
    // eslint-disable-next-line
  }, [history]);

  const redirectToHome = () => {
    history.push(URL_HOME);
  };

  const fetchGhareluUpcharList = async () => {
    const list = await new Api().get("Client/Dashboard/gharelu_upchar");

    if (list.status !== CODES.SUCCESS) {
      dispatch(showToast(list.data.message));
      return;
    }

    if (list.data.data && list.data.data.length > 0) {
      list.data.data.forEach((item) => {
        if (item.type === "video") {
          // GENERATE THUB FROM YOUTUBE VIDEO
          let youtubeUrl = item.url.split("/");
          if (youtubeUrl && youtubeUrl[3]) {
            item.url = `//img.youtube.com/vi/${youtubeUrl[3]}/0.jpg`;
          }
        }
      });
    }
    setIsLoading(false);
    setList(list.data.data);
  };

  const redirectToSubCategory = (item) => {
    history.push({
      pathname: `/gharelu-upchar/${item.id}`,
      state: {
        knowledgeCenter: item,
      },
    });
  };

  return (
    <GhareluUpcharListWrapper>
      <div className="gharelu-upchar-container">
        <div className="breadcrumbs">
          <div className="container breadcrumb">
            <div className="element pointer" onClick={redirectToHome}>
              Home
            </div>
            <div className="seperator element"> {">"} </div>
            <div className="element">{"Gharelu Upchar"}</div>
          </div>
        </div>
        <div className="container">
          {isLoading ? (
            <ProgressBar />
          ) : (
            <div className="gharelu-upchar-list">
              {list &&
                list.map((item) => (
                  <div
                    className="media-wrapper"
                    key={item.id ? item.id : Math.random()}
                    onClick={() => redirectToSubCategory(item)}
                  >
                    <div className="media-container">
                      <div
                        className="image"
                        style={{ backgroundImage: `url(${item.url})` }}
                      ></div>
                      <Tooltip title={item.title} placement="bottom">
                        <div className="name elipsis">{item.title}</div>
                      </Tooltip>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </GhareluUpcharListWrapper>
  );
}
