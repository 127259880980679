export const URL_LOGIN = "/login";
export const URL_VERIFY_OTP = "/verify-otp";

export const URL_DEFAULT = "/";
export const URL_HOME = "/";
export const URL_ADDRESS = "/address";
export const URL_CATEGORY = "/categories";
export const URL_SUB_CATEGORY = "/sub-categories/:categoryID?";
export const URL_CATEGORY_PRODUCTS = "/category-products/:categoryID";
export const URL_PRODUCT_LIST = "/product-list/:diseaseID?";
export const URL_PRODUCT = "/product/:productID";

export const URL_CART = "/cart";
export const URL_CART_CHECKOUT = "/cart-checkout";
export const URL_ORDERS = "/orders";
export const URL_ORDER_DETAILS = "/order/:orderID";

export const URL_GHARELU_UPCHAR = "/gharelu-upchar";
export const URL_GHARELU_UPCHAR_DETAILS = "/gharelu-upchar/:id";

export const URL_KNOWLEDGE_CENTER = "/knowledge-center";
export const URL_KNOWLEDGE_CENTER_DETAILS = "/knowledge-center/:id";

export const URL_DONATE_NOW = "/donte-now";
export const URL_COMING_SOON = "/coming-soon";
export const URL_ABOUT_US = "/about-us";
