import React, { Component } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import { ProductGalleryWrapper } from "../Product.style";
import LeftArrowIcon from "Assets/Images/Slider/banner_left_arrow_unfill.png";
import RightArrowIcon from "Assets/Images/Slider/banner_right_arrow_unfill.png";
import "react-image-lightbox/style.css";

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <div {...props}>{children}</div>
);
class ProductGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryCarousel: null,
      variantCarousel: null,
      photoIndex: 0,
      isOpen: false,
    };
  }

  handleOpenLightbox = (e, imageIndex) => {
    e.preventDefault();
    this.setState({ photoIndex: imageIndex, isOpen: true });
  };

  componentDidMount() {
    this.setState({
      galleryCarousel: this.slider1,
      variantCarousel: this.slider2,
    });
  }

  render() {
    const gallerySetting = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: (
        <SlickButtonFix>
          <div className="slick-next">
            <img src={RightArrowIcon} alt="Next" />
          </div>
        </SlickButtonFix>
      ),
      prevArrow: (
        <SlickButtonFix>
          <div className="slick-prev">
            <img src={LeftArrowIcon} alt="Previous" />
          </div>
        </SlickButtonFix>
      ),
    };

    const { images } = this.props;
    const { photoIndex, isOpen } = this.state;
    const productImages = [];
    images.forEach((variant) => {
      productImages.push(variant);
    });

    return (
      <ProductGalleryWrapper>
        <div className="ps-product__variants">
          <div className="ps-wrapper">
            <Slider
              {...gallerySetting}
              ref={(slider) => (this.slider1 = slider)}
              asNavFor={this.state.variantCarousel}
              className="ps-carousel inside"
            >
              {images.map((variant, index) => (
                <div
                  className="media-wrapper"
                  key={index}
                  onClick={(e) => this.handleOpenLightbox(e, index)}
                >
                  <div className="media-container">
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${variant})` }}
                    ></div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          {isOpen && (
            <Lightbox
              mainSrc={productImages[photoIndex]}
              nextSrc={productImages[(photoIndex + 1) % productImages.length]}
              prevSrc={
                productImages[
                  (photoIndex + productImages.length - 1) % productImages.length
                ]
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (photoIndex + productImages.length - 1) %
                    productImages.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % productImages.length,
                })
              }
            />
          )}
        </div>
      </ProductGalleryWrapper>
    );
  }
}

export default ProductGallery;
