import { ACTION_TYPES } from "./Actions";
import { visibilityCheck as vCS } from "Components/Commons/Sidebar/Sidebar.jsx";

const initState = {
  toast: {
    enable: false,
    message: "",
  },
  sidebar: {
    open: vCS(),
    visible: vCS(),
  }
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.TOAST_MESSAGE_ENABLE:
      return {
        ...state,
        toast: {
          enable: true,
          message: action.message,
        },
      };

    case ACTION_TYPES.TOAST_MESSAGE_DISABLE:
      return {
        ...state,
        toast: {
          enable: false,
          message: "",
        },
      };

    case ACTION_TYPES.SIDEBAR_TOGGLE:
      let newLSettings = { ...state.sidebar };
      newLSettings.open =
        typeof action.value === "boolean" ? action.value : !newLSettings.open;
      return {
        ...state,
        sidebar: newLSettings,
      };

    case ACTION_TYPES.SIDEBAR_VISIBILITY:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          visible: action.value,
        },
      };

    case ACTION_TYPES.FETCH_HIERARCHY:
      return {
        ...state,
        hierarchy: {
          ...state.hierarchy,
        },
      };

    case ACTION_TYPES.INITIAL_HIERARCHY:
      return {
        ...state,
        hierarchy: {
          ...initState.hierarchy,
        },
      };

    default:
      return state;
  }
};

export default Reducer;
