import styled from "styled-components";
import LogoSmallIcon from "Assets/Images/TopBar/small-icon.png";
import { FONTS } from "Styles/Constants";

export const ProgressBarWrapper = styled.div`
  text-align: center;
  margin: 100px 0;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 25px 0;
    border-radius: 10px;
    box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.5);

    .text {
      font-family: ${FONTS.PRIMARY_MEDIUM};
      font-size: 20px;
      padding: 0 15px;
    }

    .logo {
      height: 60px;
      width: 60px;
      background-image: url(${LogoSmallIcon});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      margin: 0 0 0 15px;
    }
  }
`;
