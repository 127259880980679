import styled from "styled-components";
import { COLORS, FONTS, responsive } from "Styles/Constants";

export const ProductWrapper = styled.div`
  overflow: auto;
  margin-top: 23px;
  background-color: #fff;
  //min-height: 48vh;
  overflow: auto;
  ${responsive.TABLET`
      margin-top: 0px;
  `}
  
  ${responsive.MOBILE`
      margin-top: 60px;
  `}
  
  .breadcrumbs {
    background-color: #edf1f0;
    .breadcrumb {
      display: flex;
      padding: 20px 15px;
      .element {
        text-transform: uppercase;
        margin-right: 10px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 16px;
      }
      .seperator {
        font-size: 18px;
      }
    }
  }
  .tags-container {
    margin: 10px 0;
    .tags {
      color: #aaaaaa;
    }
  }
  .product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .product-slider {
      width: 40%;
      ${responsive.PHABLET`
        width: 100%;
      `}
    }
    .product-details {
      width: 60%;
      padding: 15px 25px;
      box-sizing: border-box;
      ${responsive.PHABLET`
        width: 100%;
        padding: 10px;
      `}
      
      .name {
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-size: 22px;
        margin-bottom: 15px;
      }
      .actual-price {
        color: #c1c1c1;
        font-size: 22px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
      }
      .price {
        color: #ff3c3c;
        font-size: 22px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        margin: 15px 0;
        .tax {
          font-size: 14px;
          color: #000;
        }
      }
      .save {
        font-size: 22px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
        span {
          color: #ff3c3c;
        }
      }
      .button-container {
        margin-bottom: 10px;
        .root {
          text-transform: none;
          min-width: 250px;
          margin: 15px 0;
          padding: 15px 0;
          background-color: #009036;
        }
      }
    }
    .video-container {
      margin: 0 auto 25px;
      .react-player {
        //width: 900px !important;
        ${responsive.PHABLET`
          width: 330px !important;
          height: 185px !important;
          overflow-x: hidden;
        `}
      }
    }
    .description-container {
      border-top: 2px solid #c1c1c1;
      padding: 15px 0;
      width: 100%;
      .accordian {
        box-shadow: none;
      }
      .name {
        color: ${COLORS.PRIMARY};
        font-size: 20px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
      }
      .description {
        font-family: ${FONTS.PRIMARY_MEDIUM};
      }
    }
  }
`;

export const ProductGalleryWrapper = styled.div`
  .ps-product__variants {
    margin: 15px 0;

    .media-wrapper {
      .media-container {
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 350px;
        //border: 1px solid #eaeaea;
        //box-shadow: 0px 4px 5px -5px rgba(0, 0, 0, 0.5);
        cursor: pointer;

        .image {
          background-position: center;
          background-size: 100%;
          background-repeat: no-repeat;
          height: 320px;
          width: 320px;
          margin: 0 auto;
        }
      }
    }

    .slick-next {
      position: absolute;
      z-index: 1000;
      top: 40%;
      right: 2%;
      cursor: pointer;
    }

    .slick-prev {
      position: absolute;
      z-index: 1000;
      top: 40%;
      left: 2%;
      cursor: pointer;
    }
  }
`;
