import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BannerSlider from "Components/Dashboard/Includes/BannerSlider";
import CategorySlider from "Components/Dashboard/Includes/CategorySlider";
import { DashboardWrapper } from "Components/Dashboard/Dashboard.style";
import KnowledgeCenter from "Components/Dashboard/Includes/KnowledgeCenter";
import AdsSlider from "Components/Dashboard/Includes/AdsSlider";
import ProgressBar from "Components/Commons/ProgressBar/ProgressBar";
//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";

import { CATEGORY_TYPE } from "Helpers/Utils";
//import Banner1 from "Assets/Images/Ads/ads1.png";
//import Banner2 from "Assets/Images/Ads/ads2.png";
//import Banner3 from "Assets/Images/Ads/ads3.png";

import HomeBanner1 from "Assets/Images/HomeBanner/Calcurine.jpg";
import HomeBanner2 from "Assets/Images/HomeBanner/DiabCare.jpg";
import HomeBanner3 from "Assets/Images/HomeBanner/GasConstipation.jpg";
import HomeBanner4 from "Assets/Images/HomeBanner/JBK.jpg";
import HomeBanner5 from "Assets/Images/HomeBanner/PFF.jpg";
import HomeBanner6 from "Assets/Images/HomeBanner/Pitstone.jpg";
import HomeBanner7 from "Assets/Images/HomeBanner/SafedMusli.jpg";

export default function Dashboard() {
  const dispatch = useDispatch();
  const [sliderData, setSliderData] = useState(null);
  const [dashbaordSection, setDashboardSection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSliderAndVideo();

    // eslint-disable-next-line
  }, []);

  const fetchSliderAndVideo = async () => {
    const sliderData = await new Api().get(
      "Client/Dashboard/slider_and_videos"
    );

    if (sliderData.status !== CODES.SUCCESS) {
      dispatch(showToast(sliderData.data.message));
      return;
    }

    /*if (sliderData.data?.data) {
      setSliderData(sliderData.data?.data);
    }*/

    let sliderDataStatic = {
      from_sliders: [
        {
          product_id: 1,
          url: HomeBanner1,
          type: "image",
        },
        {
          product_id: 2,
          url: HomeBanner2,
          type: "image",
        },
        {
          product_id: 3,
          url: HomeBanner3,
          type: "image",
        },
        {
          product_id: 4,
          url: HomeBanner4,
          type: "image",
        },
        {
          product_id: 5,
          url: HomeBanner5,
          type: "image",
        },
        {
          product_id: 6,
          url: HomeBanner6,
          type: "image",
        },
        {
          product_id: 7,
          url: HomeBanner7,
          type: "image",
        },
      ],
    };

    setSliderData(sliderDataStatic);

    // COMBINE SLIDER DATA VIDEO WITH CATEGORY
    fetchDashboardSection(sliderData.data?.data?.videos);
  };

  const fetchDashboardSection = async (knowldegeCenterData) => {
    const dashboardSection = await new Api().get(
      "Dashboard/section"
    );

    if (dashboardSection.status !== CODES.SUCCESS) {
      dispatch(showToast(dashboardSection.data.message));
      return;
    }

    if (dashboardSection.data?.data)
      prepareDashboardSection(dashboardSection.data.data, knowldegeCenterData);
  };

  const prepareDashboardSection = (dashbaordSection, knowldegeCenterData) => {
    if (knowldegeCenterData && knowldegeCenterData.length > 0) {
      const knowledgeCenter = {
        title: "Knowledge Center",
        type: CATEGORY_TYPE.knowledgeCenter,
        list_of_data: [...knowldegeCenterData],
      };
      dashbaordSection.splice(1, 0, knowledgeCenter);

      // PREPARE ADS BANNER
      /*const banner = {
        title: "Ads Banner",
        type: "advertiseBanner",
        list_of_data: [
          {
            id: Math.random(),
            url: Banner1,
          },
          {
            id: Math.random(),
            url: Banner2,
          },
          {
            id: Math.random(),
            url: Banner3,
          },
        ],
      };

      dashbaordSection.splice(dashbaordSection.length - 2, 0, banner); */

      // UPDATE THE DATE INTO STATE
      setDashboardSection([...dashbaordSection]);
    } else {
      setDashboardSection([...dashbaordSection]);
    }
    setIsLoading(false);
  };

  const getTheCategoryComponent = (data) => {
    switch (data.type) {
      case CATEGORY_TYPE.category:
        return <CategorySlider sliderData={data} />;
      case CATEGORY_TYPE.disease:
        return <CategorySlider sliderData={data} />;
      case CATEGORY_TYPE.product:
        return <CategorySlider sliderData={data} />;
      case CATEGORY_TYPE.knowledgeCenter:
        return <KnowledgeCenter sliderData={data} />;
      case CATEGORY_TYPE.advertiseBanner:
        return <AdsSlider sliderData={data} />;
      default:
        return <CategorySlider sliderData={data} />;
    }
  };

  return (
    <DashboardWrapper>
      {sliderData &&
        sliderData.from_sliders &&
        sliderData.from_sliders.length > 0 && (
          <div className="banner-container">
            <BannerSlider sliderValue={sliderData.from_sliders} />
          </div>
        )}

      {isLoading ? (
        <ProgressBar />
      ) : (
        <React.Fragment>
          {dashbaordSection &&
            dashbaordSection.length > 0 &&
            dashbaordSection.map((item, index) => (
              <div
                className={`slider-type-wrapper ${item.type} ${
                  (index + 1) % 2 === 0 && "white-bg"
                }`}
                key={index}
              >
                {getTheCategoryComponent(item)}
              </div>
            ))}
        </React.Fragment>
      )}
    </DashboardWrapper>
  );
}
