import { createGlobalStyle } from "styled-components";
import { FONTS, responsive, COLORS } from "./Constants";

import PoppinsPrimary from "./../Assets/Fonts/Poppins/Poppins-Regular.ttf";
import PoppinsMedium from "./../Assets/Fonts/Poppins/Poppins-Medium.ttf";
import PoppinsBold from "./../Assets/Fonts/Poppins/Poppins-Bold.ttf";
import PoppinsSemiBold from "./../Assets/Fonts/Poppins/Poppins-SemiBold.ttf";
import PoppinsLight from "./../Assets/Fonts/Poppins/Poppins-Light.ttf";

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }
    html,
    body{
        margin: 0;
        padding: 0;
    }
    body{
        background-color    : #FAFAFD;
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
    }
    .full-height{
        height: 100%;
    }
    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }
    .f-v-center{
        align-items: center;
    }
    .f-h-center{
        justify-content: center;
    }

    .text-center{
        text-align: center;
    }

    .visible-md{
        display: none;
        ${responsive.TABLET`display:block`}
    }
    .hidden-md{
        ${responsive.TABLET`display:none`}
    }
    .plain-notification{
        background-color: #fff;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }

    .elipsis-2 {
        overflow        : hidden;
        text-overflow   : ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .capitalize{
        text-transform:capitalize;
    }

    .dispaly-flex{
        display : flex;
    }   

    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }

    .quarters {
        width : 100%;

        .quarter-title {
            font-size  : 16px;
            font-family : ${FONTS.PRIMARY_MEDIUM};
            color : ${COLORS.GREY_TEXT_COLOR};

            &:hover {
                color : ${COLORS.PRIMARY};
            }
        }
        .quarter-dates {
            width : 70%;
            display : flex;
            justify-content : space-between;
            align-items : center;

            .quarter-end-date,.quarter-start-date {
                font-size  : 14px;
            }

            .quarter-dash {
                margin : 0 5px;
            }
        }
    }

    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width:1320px;
        
        ${responsive.DISPLAY`
            max-width:1140px;
        `}

        ${responsive.DESKTOP`
            max-width:1140px;
        `}

        ${responsive.LAPTOP`
            max-width:960px;
        `}

        ${responsive.PHABLET`
            max-width:100%;
        `}
    }

    .pointer {
        cursor: pointer;
    }

    .error-text {
        color: red;
        height: 15px;
        margin: 2px 0 0;
        font-size: 12px;
        font-family: ${FONTS.PRIMARY};
    }
    @font-face {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: normal;
        src: local(${FONTS.PRIMARY}), url(${PoppinsPrimary}) format("truetype");
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-style: normal;
        font-weight: normal;
        src: local(${
          FONTS.PRIMARY_MEDIUM
        }), url(${PoppinsMedium}) format("truetype");
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_BOLD};
        font-style: normal;
        font-weight: normal;
        src: local(${
          FONTS.PRIMARY_BOLD
        }), url(${PoppinsBold}) format("truetype");
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-style: normal;
        font-weight: normal;
        src: local(${
          FONTS.PRIMARY_SEMI_BOLD
        }), url(${PoppinsSemiBold}) format("truetype");
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_LIGHT};
        font-style: normal;
        font-weight: normal;
        src: local(${
          FONTS.PRIMARY_LIGHT
        }), url(${PoppinsLight}) format("truetype");
    }
`;
