import React, { useEffect } from "react";
import { SidebarWrapper, UrlWrapper } from "./Sidebar.style";
import { withRouter, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { BREAKPOINTS_VALUE } from "Styles/Constants";

// REDUX
import { toggleSidebar, changeVisibilitySidebar } from "Redux/General/Actions";

function Sidebar(props) {
  //const [pages, setPages] = useState([]);
  //const [icons, setIcons] = useState();
  const icons = null
  const pages = [];

  const location = useLocation();
  const dispatch = useDispatch();
  let settings = useSelector((state) => state.General.sidebar);
  
  const redirectToLink = (url) => {
    props.history.push(url);
  };

  useEffect(() => {
    // THIS FUNCTION ADDED JUST TO PASS IN REMOVE LISTENER
    function resizeListener() {
      
      // CHANGE SIDEBAR TYPE
      let newValue = visibilityCheck();
      if (newValue !== settings.visible) {
        dispatch(changeVisibilitySidebar(newValue));
        if (!newValue) closeSidebar();
      }
    }
    window.addEventListener("resize", resizeListener);

    return function removeListener() {
      window.removeEventListener("resize", resizeListener);
    };
    // eslint-disable-next-line
  }, [settings.visible]);

  const closeSidebar = () => {
    dispatch(toggleSidebar(false));
  };

  let checkVariant = visibilityCheck();

  if (checkVariant && settings.open) checkVariant = "permanent";
  else checkVariant = "temporary";

  return (
    <SidebarWrapper
      open={settings.open}
      variant={checkVariant}
      PaperProps={{
        className: "drawer-paper",
      }}
      onClose={closeSidebar}
    >
      {pages.map((item, index) => (
        <UrlWrapper
          key={index}
          position={item.icon}
          size={item.size}
          onClick={() => redirectToLink(item.url)}
          className={`link ${item.url === location.pathname && "active"}`}
        >
          <div className="icon" style={{ backgroundImage: `url(${icons})` }} />
          <div className={`text ${item.url === location.pathname && "active"}`}>
            {item.name}
          </div>
        </UrlWrapper>
      ))}
    </SidebarWrapper>
  );
}

export function visibilityCheck() {
  return window.innerWidth >= BREAKPOINTS_VALUE.LAPTOP;
}

export default withRouter(Sidebar);
