import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoginWrapper } from "./Login.style";
import { TextField, Button } from "@material-ui/core";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";

// Formik
import { Formik, Form } from "formik";

// Yup for creating validation schema
import * as Yup from "yup";
import { URL_VERIFY_OTP, URL_HOME } from "Helpers/Paths";

const initialValues = { mobile_number: "" };

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  mobile_number: Yup.string()
    .required("Please enter mobile number.")
    .matches(phoneRegExp, "Please enter valid mobile number."),
});

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { isLoggedIn, userInfo } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (isLoggedIn && userInfo) history.push(URL_HOME);
  }, [isLoggedIn, history, userInfo]);

  const handleSubmit = async (values) => {
    let formData = new FormData();
    formData.append("mobile_number", values.mobile_number);

    if (values && values.mobile_number) {
      const loginData = await new Api().post("Client/Login", {
        data: formData,
      });

      if (loginData.status !== CODES.SUCCESS) {
        dispatch(showToast(loginData.data.message));
        return;
      }

      localStorage.setItem("loginMobile", values.mobile_number);
      history.push(URL_VERIFY_OTP);
    }
  };

  return (
    <LoginWrapper>
      <div className="logo-container">
        <div className="logo"></div>
      </div>
      <div className="login-container">
        <div className="help-container">
          <div className="help">Need Help?</div>
        </div>
        <div className="container">
          <div className="title">
            <h1>Login</h1>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleChange, values, errors, touched }) => {
              return (
                <Form>
                  <div className="field">
                    <div className="label">
                      Mobile<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        id="mobile_number"
                        name="mobile_number"
                        variant="outlined"
                        classes={{
                          root: "root",
                        }}
                        placeholder="Enter your mobile number"
                        onChange={handleChange}
                        value={values.mobile_number}
                      />
                      <div className="error-text">
                        {touched.mobile_number && errors.mobile_number}
                      </div>
                    </div>
                    <div className="button-container">
                      <Button
                        variant="contained"
                        color="primary"
                        classes={{ root: "root" }}
                        type="submit"
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </LoginWrapper>
  );
}
