import LazyLoader from "@loadable/component";

import {
  URL_HOME,
  URL_ADDRESS,
  URL_CART,
  URL_CART_CHECKOUT,
  URL_ORDERS,
  URL_ORDER_DETAILS,
} from "Helpers/Paths";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    exact: true,
    component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
  },
  {
    path: URL_HOME,
    exact: true,
    component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
  },
  {
    path: URL_ADDRESS,
    exact: true,
    component: LazyLoader(() => import("Components/Address/Address")),
  },
  {
    path: URL_CART,
    exact: true,
    component: LazyLoader(() => import("Components/Cart/Cart")),
  },
  {
    path: URL_CART_CHECKOUT,
    exact: true,
    component: LazyLoader(() => import("Components/CartCheckOut/CartCheckOut")),
  },
  {
    path: URL_ORDERS,
    exact: true,
    component: LazyLoader(() => import("Components/Orders/Orders")),
  },
  {
    path: URL_ORDER_DETAILS,
    exact: true,
    component: LazyLoader(() => import("Components/OrderDetails/OrderDetails")),
  },
];
