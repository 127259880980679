import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProductListWrapper } from "./ProductList.style";
import NoDataFound from "Components/Commons/NoDataFound/NoDataFound";
import ProgressBar from "Components/Commons/ProgressBar/ProgressBar";
import { Tooltip } from "@material-ui/core";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";

// URL
import { URL_HOME } from "Helpers/Paths";

export default function ProductList(props) {
  const dispatch = useDispatch();
  const scrollTarget = useRef();
  const params = useParams();
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (params && params.diseaseID) {
      // CLICK ON PERTICULER ID
      fetchDisesaseListByCategory(params.diseaseID);
    } else if (history.location.state && history.location.state.isHomeData) {
      // DATA COME FROM HOME PAGE SEE ALL
      setProducts([...history.location.state.products]);
      setIsLoading(false);
    } else {
      history.push(URL_HOME);
    }
    // eslint-disable-next-line
  }, [history, history.location.state]);

  const fetchDisesaseListByCategory = async (diseaseID) => {
    let formData = new FormData();
    formData.append("disease", diseaseID);

    const list = await new Api().post("Client/Products/productListByDisease", {
      data: formData,
    });

    if (list.status !== CODES.SUCCESS) {
      dispatch(showToast(list.data.message));
      return;
    }
    setProducts(list.data.data);
    setIsLoading(false);
  };

  const redirectToHome = () => {
    history.push(URL_HOME);
  };

  const redirectToProduct = (item) => {
    history.push(`/product/${item.id}`);
  };

  return (
    <ProductListWrapper ref={scrollTarget}>
      <div className="product-container">
        <div className="breadcrumbs">
          <div className="container breadcrumb">
            <div className="element pointer" onClick={redirectToHome}>
              Home
            </div>
            <div className="seperator element"> {">"} </div>
            <div className="element">
              {history.location.state &&
                history.location.state.category &&
                history.location.state.category.name}
            </div>
          </div>
        </div>
        {isLoading ? (
          <ProgressBar />
        ) : (
          <React.Fragment>
            {products && products.length > 0 ? (
              <div className="container">
                <div className="product-counter">
                  <div className="title">
                    {products.length} Products Available
                  </div>
                </div>
                <div className="product-list">
                  {products.map((item) => (
                    <div
                      className={`media-wrapper ${
                        history.location.state &&
                        history.location.state.isHomeData &&
                        "home-product"
                      }`}
                      key={item.id ? item.id : Math.random()}
                      onClick={() => redirectToProduct(item)}
                    >
                      <div className="media-container">
                        <div
                          className="image"
                          style={{ backgroundImage: `url(${item.image_url})` }}
                        ></div>
                        <Tooltip title={item.name} placement="bottom">
                          <div className="name elipsis">{item.name}</div>
                        </Tooltip>
                        {history.location.state &&
                          !history.location.state.isHomeData && (
                            <div className="price-wrapper">
                              <div className="price-container">
                                <div className="price">₹ {item.price}</div>
                                {Number(item.p_actual_price) -
                                  Number(item.price) >
                                  0 && (
                                  <div className="price">
                                    ₹ <del>{item.p_actual_price}</del>
                                  </div>
                                )}
                              </div>
                              {Number(item.p_actual_price) -
                                Number(item.price) >
                                0 && (
                                <div className="price-save">
                                  You save ₹{" "}
                                  {Number(item.p_actual_price) -
                                    Number(item.price)}
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <NoDataFound message={`Sorry! No products found.`} />
            )}
          </React.Fragment>
        )}
      </div>
    </ProductListWrapper>
  );
}
