import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfileDailogWrapper } from "./ProfileDialog.style";
import { Button, TextField, IconButton } from "@material-ui/core";
import UploadIcon from "Assets/Images/Profile/upload.svg";

//API
import Api from "Helpers/ApiHandler";
import CODES from "Helpers/StatusCodes";
import { showToast } from "Redux/General/Actions";
import { updateUserInfo } from "Redux/Auth/Actions";
// URL

// Formik
import { Formik, Form } from "formik";

// Yup for creating validation schema
import * as Yup from "yup";

const defaultInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  mobile_no: "",
};

const validationSchema = Yup.object({
  first_name: Yup.string().required("Please enter first name."),
  last_name: Yup.string().required("Please enter last name."),
  email: Yup.string()
    .email("Please enter valid email address.")
    .required("Please enter email."),
});

export default function CustomizedDialogs(props) {
  const dispatch = useDispatch();
  let { userInfo } = useSelector((state) => state.Auth);
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [imageSrc, setImageSrc] = useState();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (userInfo && userInfo.id) {
      initialValues["first_name"] = userInfo.first_name;
      initialValues["last_name"] = userInfo.last_name;
      initialValues["email"] = userInfo.email;
      initialValues["mobile_no"] = userInfo.mobile_no;
      setInitialValues({ ...initialValues });
      setImageSrc(userInfo.image);
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const handleSubmit = async (values) => {
    if (userInfo && userInfo.id) {
      let formData = new FormData();
      formData.append("client_id", userInfo.id);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);

      if (image) {
        values.profileImage = image;
        formData.append("image", values.profileImage);
      }

      // ADD Address
      const updateProfile = await new Api().post(
        "Client/Profile/update",
        {
          data: formData,
          isMultipart: true,
        }
      );

      if (updateProfile.status !== CODES.SUCCESS) {
        dispatch(showToast(updateProfile.data.message));
        return;
      }
      dispatch(showToast(updateProfile.data.message));
      dispatch(updateUserInfo(updateProfile.data.data));

      props.closeProfileDialog(true);
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setImageSrc(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <ProfileDailogWrapper
      onClose={props.closeProfileDialog}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth
    >
      <div className="title-container">
        <div className="title">My Profile</div>
        <div
          aria-label="close"
          className="close"
          onClick={props.closeProfileDialog}
        >
          X
        </div>
      </div>
      <div className="profile-container">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleChange, values, errors, touched, setFieldValue }) => {
            return (
              <Form>
                <div className="field">
                  <React.Fragment>
                    <div className="label">Upload your profile photo</div>
                    <div className="field-file-upload">
                      <input
                        accept="image/png, image/jpeg"
                        id="icon-button-file"
                        type="file"
                        className="input-file"
                        onChange={onImageChange}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          aria-label="upload picture"
                          component="span"
                          classes={{ root: "root-btn" }}
                        >
                          <div
                            className="upload"
                            style={{
                              backgroundImage: `url(${
                                imageSrc ? imageSrc : UploadIcon
                              })`,
                            }}
                          />
                        </IconButton>
                      </label>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="label">
                      First Name<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        id="first_name"
                        name="first_name"
                        variant="outlined"
                        classes={{
                          root: "root",
                        }}
                        placeholder="Enter Name"
                        onChange={handleChange}
                        value={values.first_name}
                      />
                      <div className="error-text">
                        {touched.first_name && errors.first_name}
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="label">
                      Last Name<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        id="last_name"
                        name="last_name"
                        variant="outlined"
                        classes={{
                          root: "root",
                        }}
                        placeholder="Enter Name"
                        onChange={handleChange}
                        value={values.last_name}
                      />
                      <div className="error-text">
                        {touched.last_name && errors.last_name}
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="label">
                      Email<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        id="email"
                        name="email"
                        variant="outlined"
                        classes={{
                          root: "root",
                        }}
                        placeholder="Enter email"
                        onChange={handleChange}
                        value={values.email}
                      />
                      <div className="error-text">
                        {touched.email && errors.email}
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="label">
                      Mobile<span className="required">*</span>
                    </div>
                    <div className="input">
                      <TextField
                        id="mobile_no"
                        name="mobile_no"
                        variant="outlined"
                        classes={{
                          root: "root",
                        }}
                        placeholder="Enter mobile"
                        onChange={handleChange}
                        value={values.mobile_no}
                        disabled
                      />
                      <div className="error-text">
                        {touched.mobile_no && errors.mobile_no}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
                <div className="button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{ root: "root" }}
                    type="submit"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ProfileDailogWrapper>
  );
}
