export const ACTION_TYPES = {
  TOAST_MESSAGE_ENABLE: "TOAST_MESSAGE_ENABLE",
  TOAST_MESSAGE_DISABLE: "TOAST_MESSAGE_DISABLE",

  SERVER_ERROR_ENABLE: "SERVER_ERROR_ENABLE",
  SERVER_ERROR_DISABLE: "SERVER_ERROR_DISABLE",

  SIDEBAR_TOGGLE: "SIDEBAR_TOGGLE",
  SIDEBAR_VISIBILITY: "SIDEBAR_VISIBILITY",
};

export const showToast = (message) => {
  return {
    type: ACTION_TYPES.TOAST_MESSAGE_ENABLE,
    message: message || "",
  };
};
export const hideToast = () => {
  return {
    type: ACTION_TYPES.TOAST_MESSAGE_DISABLE,
  };
};

/* SIDEBAR */
export const toggleSidebar = (value) => {
  return {
    type: ACTION_TYPES.SIDEBAR_TOGGLE,
    value,
  };
};

export const changeVisibilitySidebar = (value) => {
  return {
    type: ACTION_TYPES.SIDEBAR_VISIBILITY,
    value,
  };
};

/* SERVER ERROR */
export const showServerError = () => {
  return {
    type: ACTION_TYPES.SERVER_ERROR_ENABLE,
  };
};